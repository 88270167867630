<template>
  <div class="page-container insurance-product-manage">
    <module-header title="保险产品管理">
      <div slot="rightCom" class="rightCom" style="padding-right: 40px;">
        <el-button v-if="!isEdit" :key="1" type="primary" @click="editProduct">
          调整产品信息
        </el-button>
        <template v-else>
          <el-button :key="2" @click="cancel">
            返回
          </el-button>
          <el-button :key="3" type="primary" @click="confirm">
            完成
          </el-button>
        </template>
      </div>
    </module-header>
    <div class="search-main">
      <el-form
        label-width="100px"
        :model="searchData"
      >
        <div class="flex-row-start-start">
          <div
            style="position: relative;left: -53px;"
          >
            <formItem
              v-model="searchData.insuranceType"
              prop="insuranceType"
              label="选择保险产品类型:"
              type="select"
              direct="left"
              :options="typeOptions"
              double
              :disabled="isEdit"
              :clearable="true"
              @change="changeType"
            />
          </div>
          <div style="padding-top: 4px;padding-left: 40px;">
            <el-button v-if="isEdit" type="text" @click="addProduct">
              添加产品
            </el-button>
          </div>
        </div>
      </el-form>
    </div>
    <div class="table-container">
      <template v-if="!isEdit">
        <template
          v-if="searchData.insuranceType === 'ChildrenEdu'"
        >
          <el-table
            key="ChildrenEdu"
            :data="tableData"
            border
            class="info-table"
            style="width: 100%"
            :height="tableHeight"
          >
            <el-table-column
              prop="name"
              label="产品名称"
              align="center"
              width="200"
              :show-overflow-tooltip="true"
            />
            <el-table-column
              prop="annuityTypeStr"
              label="年金类型"
              align="center"
            />
            <el-table-column
              label="包含万能账户"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.universalAccount ? '是' : '否' }}
              </template>
            </el-table-column>
            <el-table-column
              label="包含保险分红"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.insuranceDividend ? '是' : '否' }}
              </template>
            </el-table-column>
            <el-table-column
              label="包含婚嫁金"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.containsMarriageFunds ? '是' : '否' }}
              </template>
            </el-table-column>
            <el-table-column
              prop="company"
              label="所属保司"
              align="center"
              :show-overflow-tooltip="true"
            />
            <el-table-column
              prop="ranking"
              label="产品综合排名"
              align="center"
            />
            <el-table-column
              prop="cashValue"
              label="现金价值排名"
              align="center"
            />
            <el-table-column
              prop="insureAgeFloor"
              label="投保年龄下限"
              align="center"
            />
            <el-table-column
              prop="insureAgeLimit"
              label="投保年龄上限"
              align="center"
            />
            <el-table-column
              prop="paymentTerms"
              label="缴费期限"
              align="center"
            />
            <el-table-column
              prop="insureRule"
              label="投保规则"
              show-overflow-tooltip
              align="center"
            />
            <el-table-column
              prop="insuranceClause"
              label="保险条款"
              show-overflow-tooltip
              align="center"
            />
            <el-table-column
              prop="marketingData"
              label=" 营销材料"
              show-overflow-tooltip
              align="center"
            />
            <el-table-column
              fixed="right"
              label="调整产品详表"
              align="center"
              width="120"
            >
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="small"
                  @click="showDialog(scope.row)"
                >
                  {{ scope.row.infoCounts ? '调整' : '添加' }}
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <template
          v-if="searchData.insuranceType === 'CriticalIllnessInsurance'"
        >
          <el-table
            key="CriticalIllnessInsurance"
            :data="tableData"
            border
            class="info-table"
            style="width: 100%"
            :height="tableHeight"
          >
            <el-table-column
              prop="name"
              label="产品名称"
              align="center"
              width="200"
              :show-overflow-tooltip="true"
            />
            <el-table-column
              label="重疾类型"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.lifelongIllness ? '终身重疾' : '定期重疾' }}
              </template>
            </el-table-column>
            <el-table-column
              label="满期金"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.containsExpireAmount ? '是' : '否' }}
              </template>
            </el-table-column>
            <el-table-column
              label="包含保险分红"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.insuranceDividend ? '是' : '否' }}
              </template>
            </el-table-column>
            <el-table-column
              label="重疾分组"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.containsGroupingIllness ? '是' : '否' }}
              </template>
            </el-table-column>
            <el-table-column
              prop="company"
              label="所属保司"
              align="center"
              :show-overflow-tooltip="true"
            />
            <el-table-column
              prop="ranking"
              label="产品综合排名"
              align="center"
            />
            <el-table-column
              prop="cashValue"
              label="价格排名"
              align="center"
            />
            <el-table-column
              prop="coverIllnessNumbers"
              label="重疾包含数排名"
              align="center"
            />
            <el-table-column
              prop="insureAgeFloor"
              label="投保年龄下限"
              align="center"
            />
            <el-table-column
              prop="insureAgeLimit"
              label="投保年龄上限"
              align="center"
            />
            <el-table-column
              prop="paymentTerms"
              label="缴费期限"
              align="center"
            />
            <el-table-column
              prop="insureRule"
              label="投保规则"
              show-overflow-tooltip
              align="center"
            />
            <el-table-column
              prop="insuranceClause"
              label="保险条款"
              show-overflow-tooltip
              align="center"
            />
            <el-table-column
              prop="marketingData"
              label=" 营销材料"
              show-overflow-tooltip
              align="center"
            />
            <el-table-column
              fixed="right"
              label="调整产品详表"
              align="center"
              width="120"
            >
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="small"
                  @click="showDialog(scope.row)"
                >
                  {{ scope.row.infoCounts ? '调整' : '添加' }}
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <template
          v-if="searchData.insuranceType === 'LifeInsurance'"
        >
          <el-table
            key="LifeInsurance"
            :data="tableData"
            border
            class="info-table"
            style="width: 100%"
            :height="tableHeight"
          >
            <el-table-column
              prop="name"
              label="产品名称"
              align="center"
              width="200"
              :show-overflow-tooltip="true"
            />
            <el-table-column
              label="寿险类型"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.containsRegularLife ? '定期寿险' : '终身寿险' }}
              </template>
            </el-table-column>
            <el-table-column
              label="包含保险分红"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.insuranceDividend ? '是' : '否' }}
              </template>
            </el-table-column>
            <el-table-column
              label="是否为增额终身寿险"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.lifelongLifeInsurance ? '是' : '否' }}
              </template>
            </el-table-column>
            <el-table-column
              prop="company"
              label="所属保司"
              align="center"
              :show-overflow-tooltip="true"
            />
            <el-table-column
              prop="ranking"
              label="产品综合排名"
              align="center"
            />
            <el-table-column
              prop="cashValue"
              label="现金价值排名"
              align="center"
            />
            <el-table-column
              prop="insureAgeFloor"
              label="投保年龄下限"
              align="center"
            />
            <el-table-column
              prop="insureAgeLimit"
              label="投保年龄上限"
              align="center"
            />
            <el-table-column
              prop="paymentTerms"
              label="缴费期限"
              align="center"
            />
            <el-table-column
              prop="insureRule"
              label="投保规则"
              show-overflow-tooltip
              align="center"
            />
            <el-table-column
              prop="insuranceClause"
              label="保险条款"
              show-overflow-tooltip
              align="center"
            />
            <el-table-column
              prop="marketingData"
              label=" 营销材料"
              show-overflow-tooltip
              align="center"
            />
            <el-table-column
              fixed="right"
              label="调整产品详表"
              align="center"
              width="120"
            >
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="small"
                  @click="showDialog(scope.row)"
                >
                  {{ scope.row.infoCounts ? '调整' : '添加' }}
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <template
          v-if="searchData.insuranceType === 'Pension'"
        >
          <el-table
            key="Pension"
            :data="tableData"
            border
            class="info-table"
            style="width: 100%"
            :height="tableHeight"
          >
            <el-table-column
              prop="name"
              label="产品名称"
              align="center"
              width="200"
              :show-overflow-tooltip="true"
            />
            <el-table-column
              prop="annuityTypeStr"
              label="年金类型"
              align="center"
            />
            <el-table-column
              label="包含万能账户"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.universalAccount ? '是' : '否' }}
              </template>
            </el-table-column>
            <el-table-column
              label="包含保险分红"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.insuranceDividend ? '是' : '否' }}
              </template>
            </el-table-column>
            <el-table-column
              prop="company"
              label="所属保司"
              align="center"
              :show-overflow-tooltip="true"
            />
            <el-table-column
              prop="ranking"
              label="产品综合排名"
              align="center"
            />
            <el-table-column
              prop="cashValue"
              label="现金价值排名"
              align="center"
            />
            <el-table-column
              prop="insureAgeFloor"
              label="投保年龄下限"
              align="center"
            />
            <el-table-column
              prop="insureAgeLimit"
              label="投保年龄上限"
              align="center"
            />
            <el-table-column
              prop="paymentTerms"
              label="缴费期限"
              align="center"
            />
            <el-table-column
              prop="insureRule"
              label="投保规则"
              show-overflow-tooltip
              align="center"
            />
            <el-table-column
              prop="insuranceClause"
              label="保险条款"
              show-overflow-tooltip
              align="center"
            />
            <el-table-column
              prop="marketingData"
              label=" 营销材料"
              show-overflow-tooltip
              align="center"
            />
            <el-table-column
              fixed="right"
              label="调整产品详表"
              align="center"
              width="120"
            >
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="small"
                  @click="showDialog(scope.row)"
                >
                  {{ scope.row.infoCounts ? '调整' : '添加' }}
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </template>
      <template v-else>
        <template
          v-if="searchData.insuranceType === 'ChildrenEdu'"
        >
          <el-table
            key="EditChildrenEdu"
            :data="editTableData"
            border
            class="info-table"
            style="width: 100%"
            :height="tableHeight"
          >
            <el-table-column
              label="产品名称"
              align="center"
              width="200"
              :show-overflow-tooltip="true"
            >
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.name"
                  placeholder="请输入产品名称"
                />
              </template>
            </el-table-column>
            <el-table-column
              label="年金类型"
              align="center"
            >
              <template slot-scope="scope">
                <el-select
                  v-model="scope.row.annuityType"
                  placeholder="请选择年金类型"
                >
                  <el-option
                    v-for="item in [
                      {
                        label: '子女教育年金',
                        value: 'ChildrenEduAnnuity'
                      }, {
                        label: '标准计期年金',
                        value: 'StandardTermAnnuity'
                      }
                    ]"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </template>
            </el-table-column>
            <el-table-column
              label="包含万能账户"
              align="center"
            >
              <template slot-scope="scope">
                <el-select
                  v-model="scope.row.universalAccountSelect"
                  placeholder="请选择包含万能账户"
                >
                  <el-option
                    v-for="item in [
                      {
                        label: '是',
                        value: '1'
                      }, {
                        label: '否',
                        value: '2'
                      }
                    ]"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </template>
            </el-table-column>
            <el-table-column
              label="包含保险分红"
              align="center"
            >
              <template slot-scope="scope">
                <el-select
                  v-model="scope.row.insuranceDividendSelect"
                  placeholder="请选择包含保险分红"
                >
                  <el-option
                    v-for="item in [
                      {
                        label: '是',
                        value: '1'
                      }, {
                        label: '否',
                        value: '2'
                      }
                    ]"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </template>
            </el-table-column>
            <el-table-column
              label="包含婚嫁金"
              align="center"
            >
              <template slot-scope="scope">
                <el-select
                  v-model="scope.row.containsMarriageFundsSelect"
                  placeholder="请选择包含婚嫁金"
                >
                  <el-option
                    v-for="item in [
                      {
                        label: '是',
                        value: '1'
                      }, {
                        label: '否',
                        value: '2'
                      }
                    ]"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </template>
            </el-table-column>
            <el-table-column
              label="所属保司"
              align="center"
              :show-overflow-tooltip="true"
            >
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.company"
                  placeholder="请输入所属保司"
                />
              </template>
            </el-table-column>
            <el-table-column
              label="产品综合排名"
              align="center"
            >
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.ranking"
                  placeholder="请输入产品综合排名"
                />
              </template>
            </el-table-column>
            <el-table-column
              label="现金价值排名"
              align="center"
            >
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.cashValue"
                  placeholder="请输入现金价值排名"
                />
              </template>
            </el-table-column>
            <el-table-column
              label="投保年龄下限"
              align="center"
            >
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.insureAgeFloor"
                  placeholder="请输入投保年龄下限"
                />
              </template>
            </el-table-column>
            <el-table-column
              label="投保年龄上限"
              align="center"
            >
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.insureAgeLimit"
                  placeholder="请输入投保年龄上限"
                />
              </template>
            </el-table-column>
            <el-table-column
              label="缴费期限（以“,”分隔）"
              align="center"
              width="170"
            >
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.paymentTerms"
                  placeholder="请输入缴费期限"
                />
              </template>
            </el-table-column>
            <el-table-column
              label="投保规则"
              align="center"
              width="170"
            >
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.insureRule"
                  placeholder="请输入投保规则地址"
                />
              </template>
            </el-table-column>
            <el-table-column
              label="保险条款"
              align="center"
              width="170"
            >
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.insuranceClause"
                  placeholder="请输入保险条款地址"
                />
              </template>
            </el-table-column>
            <el-table-column
              label="营销材料"
              align="center"
              width="170"
            >
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.marketingData"
                  placeholder="请输入缴费期限"
                />
              </template>
            </el-table-column>
            <el-table-column
              fixed="right"
              label="操作"
              align="center"
              width="60"
            >
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="small"
                  @click="deleteItem(scope.row, scope.$index)"
                >
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <template
          v-if="searchData.insuranceType === 'CriticalIllnessInsurance'"
        >
          <el-table
            key="EditCriticalIllnessInsurance"
            :data="editTableData"
            border
            class="info-table"
            style="width: 100%"
            :height="tableHeight"
          >
            <el-table-column
              label="产品名称"
              align="center"
              width="200"
              :show-overflow-tooltip="true"
            >
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.name"
                  placeholder="请输入产品名称"
                />
              </template>
            </el-table-column>
            <el-table-column
              label="重疾类型"
              align="center"
            >
              <template slot-scope="scope">
                <el-select
                  v-model="scope.row.lifelongIllnessSelect"
                  placeholder="请选择重疾类型"
                >
                  <el-option
                    v-for="item in [
                      {
                        label: '终身重疾',
                        value: '1'
                      }, {
                        label: '定期重疾',
                        value: '2'
                      }
                    ]"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </template>
            </el-table-column>
            <el-table-column
              label="满期金"
              align="center"
            >
              <template slot-scope="scope">
                <el-select
                  v-model="scope.row.containsExpireAmountSelect"
                  placeholder="请选择满期金"
                >
                  <el-option
                    v-for="item in [
                      {
                        label: '是',
                        value: '1'
                      }, {
                        label: '否',
                        value: '2'
                      }
                    ]"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </template>
            </el-table-column>
            <el-table-column
              label="包含保险分红"
              align="center"
            >
              <template slot-scope="scope">
                <el-select
                  v-model="scope.row.insuranceDividendSelect"
                  placeholder="请选择包含保险分红"
                >
                  <el-option
                    v-for="item in [
                      {
                        label: '是',
                        value: '1'
                      }, {
                        label: '否',
                        value: '2'
                      }
                    ]"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </template>
            </el-table-column>
            <el-table-column
              label="重疾分组"
              align="center"
            >
              <template slot-scope="scope">
                <el-select
                  v-model="scope.row.containsGroupingIllnessSelect"
                  placeholder="请选择重疾分组"
                >
                  <el-option
                    v-for="item in [
                      {
                        label: '是',
                        value: '1'
                      }, {
                        label: '否',
                        value: '2'
                      }
                    ]"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </template>
            </el-table-column>
            <el-table-column
              label="所属保司"
              align="center"
              :show-overflow-tooltip="true"
            >
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.company"
                  placeholder="请输入所属保司"
                />
              </template>
            </el-table-column>
            <el-table-column
              label="产品综合排名"
              align="center"
            >
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.ranking"
                  placeholder="请输入产品综合排名"
                />
              </template>
            </el-table-column>
            <el-table-column
              label="价格排名"
              align="center"
            >
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.cashValue"
                  placeholder="请输入现金价值排名"
                />
              </template>
            </el-table-column>
            <el-table-column
              label="重疾包含数排名"
              align="center"
            >
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.coverIllnessNumbers"
                  placeholder="请输入重疾包含数排名"
                />
              </template>
            </el-table-column>
            <el-table-column
              label="投保年龄下限"
              align="center"
            >
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.insureAgeFloor"
                  placeholder="请输入投保年龄下限"
                />
              </template>
            </el-table-column>
            <el-table-column
              label="投保年龄上限"
              align="center"
            >
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.insureAgeLimit"
                  placeholder="请输入投保年龄上限"
                />
              </template>
            </el-table-column>
            <el-table-column
              label="缴费期限（以“,”分隔）"
              align="center"
              width="170"
            >
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.paymentTerms"
                  placeholder="请输入缴费期限"
                />
              </template>
            </el-table-column>
            <el-table-column
              label="投保规则"
              align="center"
              width="170"
            >
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.insureRule"
                  placeholder="请输入投保规则地址"
                />
              </template>
            </el-table-column>
            <el-table-column
              label="保险条款"
              align="center"
              width="170"
            >
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.insuranceClause"
                  placeholder="请输入保险条款地址"
                />
              </template>
            </el-table-column>
            <el-table-column
              label="营销材料"
              align="center"
              width="170"
            >
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.marketingData"
                  placeholder="请输入缴费期限"
                />
              </template>
            </el-table-column>
            <el-table-column
              fixed="right"
              label="操作"
              align="center"
              width="60"
            >
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="small"
                  @click="deleteItem(scope.row, scope.$index)"
                >
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <template
          v-if="searchData.insuranceType === 'LifeInsurance'"
        >
          <el-table
            key="EditLifeInsurance"
            :data="editTableData"
            border
            class="info-table"
            style="width: 100%"
            :height="tableHeight"
          >
            <el-table-column
              label="产品名称"
              align="center"
              width="200"
              :show-overflow-tooltip="true"
            >
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.name"
                  placeholder="请输入产品名称"
                />
              </template>
            </el-table-column>
            <el-table-column
              label="寿险类型"
              align="center"
            >
              <template slot-scope="scope">
                <el-select
                  v-model="scope.row.containsRegularLifeSelect"
                  placeholder="请选择寿险类型"
                >
                  <el-option
                    v-for="item in [
                      {
                        label: '定期寿险',
                        value: '1'
                      }, {
                        label: '终身寿险',
                        value: '2'
                      }
                    ]"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </template>
            </el-table-column>
            <el-table-column
              label="包含保险分红"
              align="center"
            >
              <template slot-scope="scope">
                <el-select
                  v-model="scope.row.insuranceDividendSelect"
                  placeholder="请选择包含保险分红"
                >
                  <el-option
                    v-for="item in [
                      {
                        label: '是',
                        value: '1'
                      }, {
                        label: '否',
                        value: '2'
                      }
                    ]"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </template>
            </el-table-column>
            <el-table-column
              label="是否为增额终身寿险"
              align="center"
            >
              <template slot-scope="scope">
                <el-select
                  v-model="scope.row.lifelongLifeInsuranceSelect"
                  placeholder="请选择是否为增额终身寿险"
                >
                  <el-option
                    v-for="item in [
                      {
                        label: '是',
                        value: '1'
                      }, {
                        label: '否',
                        value: '2'
                      }
                    ]"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </template>
            </el-table-column>
            <el-table-column
              label="所属保司"
              align="center"
              :show-overflow-tooltip="true"
            >
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.company"
                  placeholder="请输入所属保司"
                />
              </template>
            </el-table-column>
            <el-table-column
              label="产品综合排名"
              align="center"
            >
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.ranking"
                  placeholder="请输入产品综合排名"
                />
              </template>
            </el-table-column>
            <el-table-column
              label="现金价值排名"
              align="center"
            >
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.cashValue"
                  placeholder="请输入现金价值排名"
                />
              </template>
            </el-table-column>
            <el-table-column
              label="投保年龄下限"
              align="center"
            >
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.insureAgeFloor"
                  placeholder="请输入投保年龄下限"
                />
              </template>
            </el-table-column>
            <el-table-column
              label="投保年龄上限"
              align="center"
            >
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.insureAgeLimit"
                  placeholder="请输入投保年龄上限"
                />
              </template>
            </el-table-column>
            <el-table-column
              label="缴费期限（以“,”分隔）"
              align="center"
              width="170"
            >
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.paymentTerms"
                  placeholder="请输入缴费期限"
                />
              </template>
            </el-table-column>
            <el-table-column
              label="投保规则"
              align="center"
              width="170"
            >
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.insureRule"
                  placeholder="请输入投保规则地址"
                />
              </template>
            </el-table-column>
            <el-table-column
              label="保险条款"
              align="center"
              width="170"
            >
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.insuranceClause"
                  placeholder="请输入保险条款地址"
                />
              </template>
            </el-table-column>
            <el-table-column
              label="营销材料"
              align="center"
              width="170"
            >
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.marketingData"
                  placeholder="请输入缴费期限"
                />
              </template>
            </el-table-column>
            <el-table-column
              fixed="right"
              label="操作"
              align="center"
              width="60"
            >
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="small"
                  @click="deleteItem(scope.row, scope.$index)"
                >
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <template
          v-if="searchData.insuranceType === 'Pension'"
        >
          <el-table
            key="EditPension"
            :data="editTableData"
            border
            class="info-table"
            style="width: 100%"
            :height="tableHeight"
          >
            <el-table-column
              label="产品名称"
              align="center"
              width="200"
              :show-overflow-tooltip="true"
            >
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.name"
                  placeholder="请输入产品名称"
                />
              </template>
            </el-table-column>
            <el-table-column
              label="年金类型"
              align="center"
            >
              <template slot-scope="scope">
                <el-select
                  v-model="scope.row.annuityType"
                  placeholder="请选择年金类型"
                >
                  <el-option
                    v-for="item in [
                      {
                        label: '养老年金',
                        value: 'PensionAnnuity'
                      }, {
                        label: '标准计期年金',
                        value: 'StandardTermAnnuity'
                      }
                    ]"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </template>
            </el-table-column>
            <el-table-column
              label="包含万能账户"
              align="center"
            >
              <template slot-scope="scope">
                <el-select
                  v-model="scope.row.universalAccountSelect"
                  placeholder="请选择包含万能账户"
                >
                  <el-option
                    v-for="item in [
                      {
                        label: '是',
                        value: '1'
                      }, {
                        label: '否',
                        value: '2'
                      }
                    ]"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </template>
            </el-table-column>
            <el-table-column
              label="包含保险分红"
              align="center"
            >
              <template slot-scope="scope">
                <el-select
                  v-model="scope.row.insuranceDividendSelect"
                  placeholder="请选择包含保险分红"
                >
                  <el-option
                    v-for="item in [
                      {
                        label: '是',
                        value: '1'
                      }, {
                        label: '否',
                        value: '2'
                      }
                    ]"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </template>
            </el-table-column>
            <el-table-column
              label="所属保司"
              align="center"
              :show-overflow-tooltip="true"
            >
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.company"
                  placeholder="请输入所属保司"
                />
              </template>
            </el-table-column>
            <el-table-column
              label="产品综合排名"
              align="center"
            >
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.ranking"
                  placeholder="请输入产品综合排名"
                />
              </template>
            </el-table-column>
            <el-table-column
              label="现金价值排名"
              align="center"
            >
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.cashValue"
                  placeholder="请输入现金价值排名"
                />
              </template>
            </el-table-column>
            <el-table-column
              label="投保年龄下限"
              align="center"
            >
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.insureAgeFloor"
                  placeholder="请输入投保年龄下限"
                />
              </template>
            </el-table-column>
            <el-table-column
              label="投保年龄上限"
              align="center"
            >
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.insureAgeLimit"
                  placeholder="请输入投保年龄上限"
                />
              </template>
            </el-table-column>
            <el-table-column
              label="缴费期限（以“,”分隔）"
              align="center"
              width="170"
            >
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.paymentTerms"
                  placeholder="请输入缴费期限"
                />
              </template>
            </el-table-column>
            <el-table-column
              label="投保规则"
              align="center"
              width="170"
            >
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.insureRule"
                  placeholder="请输入投保规则地址"
                />
              </template>
            </el-table-column>
            <el-table-column
              label="保险条款"
              align="center"
              width="170"
            >
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.insuranceClause"
                  placeholder="请输入保险条款地址"
                />
              </template>
            </el-table-column>
            <el-table-column
              label="营销材料"
              align="center"
              width="170"
            >
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.marketingData"
                  placeholder="请输入缴费期限"
                />
              </template>
            </el-table-column>
            <el-table-column
              fixed="right"
              label="操作"
              align="center"
              width="60"
            >
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="small"
                  @click="deleteItem(scope.row, scope.$index)"
                >
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </template>
    </div>
    <form-dialog
      ref="formDialog"
      :type="searchData.insuranceType"
      @success="resetFetch"
    />
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator'
import SplitPane from 'vue-splitpane'
import moduleHeader from '@/components/moduleHeader/index.vue'
import formItem from '@/components/formItem/index.vue'
import FormDialog from './template/insuranceDetailForms.vue'
import { cloneDeep } from 'lodash'
import {
  fetchInsuranceProductPage,
  fetchSaveInsuranceProduct,
  fetchSaveInsuranceProductList
} from '@/api/insuranceProduct'

@Component({
  name: 'pensionPlan',
  components: {
    SplitPane,
    moduleHeader,
    formItem,
    FormDialog
  }
})

export default class extends Vue {
  isEdit = false
  searchData = {
    insuranceType: 'ChildrenEdu'
  }
  typeOptions = [
    {
      label: '子女教育规划年金险',
      value: 'ChildrenEdu'
    }, {
      label: '家庭基础保障重疾险',
      value: 'CriticalIllnessInsurance'
    }, {
      label: '家庭基础保障寿险',
      value: 'LifeInsurance'
    }, {
      label: '养老规划年金险',
      value: 'Pension'
    }
  ]
  tableData = []
  editTableData = []
  tableHeight = 0
  showDialog(row) {
    this.$refs.formDialog.show(row)
  }
  resetFetch() {
    this.fetch()
  }
  confirm() {
    const _data = cloneDeep(this.editTableData)
    if (!(_data && _data.length)) {
      this.cancel()
    } else {
      let bFlag = false
      let errMsg = ''
      _data.forEach((item) => {
        if (!bFlag) {
          if (!item.name) {
            bFlag = true
            errMsg = '请输入产品名称'
          }
          if (!item.ranking) {
            bFlag = true
            errMsg = '请输入产品综合排名'
          }
          if (!item.company) {
            bFlag = true
            errMsg = '请输入所属保司'
          }
          if (!item.insureAgeFloor) {
            bFlag = true
            errMsg = '请输入投保年龄下限'
          }
          if (!item.insureAgeLimit) {
            bFlag = true
            errMsg = '请输入投保年龄上限'
          }
          if (!item.paymentTerms) {
            bFlag = true
            errMsg = '请输入缴费期限'
          }
          if (this.searchData.insuranceType === 'ChildrenEdu') {
            if (!item.annuityType) {
              bFlag = true
              errMsg = '请选择年金类型'
            }
            if (!item.universalAccountSelect) {
              bFlag = true
              errMsg = '请选择是否包含万能账户'
            }
            if (!item.insuranceDividendSelect) {
              bFlag = true
              errMsg = '请选择是否包含保险分红'
            }
            if (!item.containsMarriageFundsSelect) {
              bFlag = true
              errMsg = '请选择是否包含婚嫁金'
            }
            if (!item.cashValue) {
              bFlag = true
              errMsg = '请输入现金价值排名'
            }
          } else if (this.searchData.insuranceType === 'CriticalIllnessInsurance') {
            if (!item.lifelongIllnessSelect) {
              bFlag = true
              errMsg = '请选择重疾类型'
            }
            if (!item.containsExpireAmountSelect) {
              bFlag = true
              errMsg = '请选择满期金'
            }
            if (!item.insuranceDividendSelect) {
              bFlag = true
              errMsg = '请选择是否包含保险分红'
            }
            if (!item.containsGroupingIllnessSelect) {
              bFlag = true
              errMsg = '请选择重疾分组'
            }
            if (!item.cashValue) {
              bFlag = true
              errMsg = '请输入价格排名'
            }
            if (!item.coverIllnessNumbers) {
              bFlag = true
              errMsg = '请输入重疾包含数排名'
            }
          } else if (this.searchData.insuranceType === 'LifeInsurance') {
            if (!item.containsRegularLifeSelect) {
              bFlag = true
              errMsg = '请选择寿险类型'
            }
            if (!item.insuranceDividendSelect) {
              bFlag = true
              errMsg = '请选择是否包含保险分红'
            }
            if (!item.lifelongLifeInsuranceSelect) {
              bFlag = true
              errMsg = '请选择是否为增额终身寿险'
            }
            if (!item.cashValue) {
              bFlag = true
              errMsg = '请输入现金价值排名'
            }
          } else if (this.searchData.insuranceType === 'Pension') {
            if (!item.annuityType) {
              bFlag = true
              errMsg = '请选择年金类型'
            }
            if (!item.universalAccountSelect) {
              bFlag = true
              errMsg = '请选择是否包含万能账户'
            }
            if (!item.insuranceDividendSelect) {
              bFlag = true
              errMsg = '请选择是否包含保险分红'
            }
            if (!item.cashValue) {
              bFlag = true
              errMsg = '请输入现金价值排名'
            }
          }
        }
      })
      if (bFlag) {
        this.$message.error(errMsg)
      } else {
        if (this.searchData.insuranceType === 'ChildrenEdu') {
          _data.forEach((item) => {
            item.universalAccountSelect === '1' ? item.universalAccount = true : item.universalAccount = false
            item.insuranceDividendSelect === '1' ? item.insuranceDividend = true : item.insuranceDividend = false
            item.containsMarriageFundsSelect === '1' ? item.containsMarriageFunds = true : item.containsMarriageFunds = false
          })
        } else if (this.searchData.insuranceType === 'CriticalIllnessInsurance') {
          _data.forEach((item) => {
            item.lifelongIllnessSelect === '1' ? item.lifelongIllness = true : item.lifelongIllness = false
            item.containsExpireAmountSelect === '1' ? item.containsExpireAmount = true : item.containsExpireAmount = false
            item.insuranceDividendSelect === '1' ? item.insuranceDividend = true : item.insuranceDividend = false
            item.containsGroupingIllnessSelect === '1' ? item.containsGroupingIllness = true : item.containsGroupingIllness = false
          })
        } else if (this.searchData.insuranceType === 'LifeInsurance') {
          _data.forEach((item) => {
            item.containsRegularLifeSelect === '1' ? item.containsRegularLife = true : item.containsRegularLife = false
            item.insuranceDividendSelect === '1' ? item.insuranceDividend = true : item.insuranceDividend = false
            item.lifelongLifeInsuranceSelect === '1' ? item.lifelongLifeInsurance = true : item.lifelongLifeInsurance = false
          })
        } else if (this.searchData.insuranceType === 'Pension') {
          _data.forEach((item) => {
            item.universalAccountSelect === '1' ? item.universalAccount = true : item.universalAccount = false
            item.insuranceDividendSelect === '1' ? item.insuranceDividend = true : item.insuranceDividend = false
          })
        }
        _data.forEach((item) => {
          item.insuranceType = this.searchData.insuranceType
          delete item.universalAccountSelect
          delete item.insuranceDividendSelect
          delete item.containsMarriageFundsSelect
          delete item.lifelongIllnessSelect
          delete item.containsExpireAmountSelect
          delete item.containsGroupingIllnessSelect
          delete item.containsRegularLifeSelect
          delete item.lifelongLifeInsuranceSelect
        })
        fetchSaveInsuranceProductList(_data).then((res) => {
          if (res.success) {
            this.$message.success('操作成功!')
            this.cancel()
            this.fetch()
          }
        })
      }
    }
  }
  addProduct() {
    this.editTableData.push({})
  }
  deleteItem(row, idx) {
    let msg = '是否删除空行?'
    if (row.name) {
      msg = `是否删除${row.name}?`
    }
    this.$confirm(msg, '删除产品', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      let bFlag = false
      let i = 0
      this.tableData.forEach((item, index) => {
        if (item.id === row.id) {
          bFlag = true
          i = index
        }
      })
      if (bFlag) {
        const _data = cloneDeep(this.tableData[i])
        _data.deleted = true
        fetchSaveInsuranceProduct(_data).then((res) => {
          if (res.success) {
            this.tableData.splice(i, 1)
            this.editTableData.splice(idx, 1)
          }
        })
      } else {
        this.editTableData.splice(idx, 1)
      }
    }).catch(() => {})
  }
  editProduct() {
    this.editTableData = cloneDeep(this.tableData)
    this.isEdit = true
  }
  cancel() {
    this.isEdit = false
  }
  changeType() {
    this.fetch()
  }
  fetch() {
    const data = cloneDeep(this.searchData)
    if (!data.insuranceType) {
      delete data.insuranceType
    }
    fetchInsuranceProductPage(data).then((res) => {
      if (res.success) {
        const list = res.data || []
        list.forEach((item) => {
          if (item.annuityType === 'PensionAnnuity') {
            item.annuityTypeStr = '养老年金'
          } else if (item.annuityType === 'ChildrenEduAnnuity') {
            item.annuityTypeStr = '子女教育年金'
          } else if (item.annuityType === 'StandardTermAnnuity') {
            item.annuityTypeStr = '标准计期年金'
          } else {
            item.annuityTypeStr = ''
          }
          item.universalAccountSelect = item.universalAccount ? '1' : '2'
          item.insuranceDividendSelect = item.insuranceDividend ? '1' : '2'
          item.containsMarriageFundsSelect = item.containsMarriageFunds ? '1' : '2'
          item.lifelongIllnessSelect = item.lifelongIllness ? '1' : '2'
          item.containsExpireAmountSelect = item.containsExpireAmount ? '1' : '2'
          item.containsGroupingIllnessSelect = item.containsGroupingIllness ? '1' : '2'
          item.containsRegularLifeSelect = item.containsRegularLife ? '1' : '2'
          item.lifelongLifeInsuranceSelect = item.lifelongLifeInsurance ? '1' : '2'
        })
        this.tableData = list
      } else {
        this.tableData = []
      }
    })
  }
  onResize() {
    let tableHeight = document.body.clientHeight - 267
    tableHeight = tableHeight > 100 ? tableHeight : 100
    this.$nextTick(() => {
      this.tableHeight = tableHeight
    })
  }
  mounted() {
    window.addEventListener('resize', this.onResize, false)
    this.onResize()
    this.fetch()
  }
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  }
}
</script>
<style lang="scss">
.insurance-product-manage{
  .table-container{
    padding: 0 47px 20px 47px;
    width: 100%;
    .el-table .el-table__body td{
      color: $fontGray;
      font-size: 14px;
    }
    .el-table th > .cell{
      font-weight: 500;
      font-size: 14px;
      color: #3a3a3a;
    }
    .el-select .el-input {
      width: 110px
    }
  }
}
</style>
<style lang="scss" scoped>
.search-main{
  padding: 20px 0;
  position: relative;
}
</style>
