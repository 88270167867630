import request from '@/utils/request'

/**
 *  获取保险产品列表
 */
export const fetchInsuranceProductPage = (params: any) =>
  request({
    url: '/blackcat/insurance/list',
    method: 'get',
    params
  })

/**
 *  保险产品列表保存
 */
export const fetchSaveInsuranceProductList = (data: any) =>
  request({
    url: '/blackcat/insurance/batch/save',
    method: 'post',
    data,
    headers: {
      contentType: 'application/json'
    }
  })

/**
 *  保险产品保存(包含删除)
 */
export const fetchSaveInsuranceProduct = (data: any) =>
  request({
    url: '/blackcat/insurance/save',
    method: 'post',
    data,
    headers: {
      contentType: 'application/json'
    }
  })

/**
 *  信息表文件上传（子女、养老）
 */
export const fetchUpdateInsuranceFile1 = (data: any) =>
  request({
    url: '/blackcat/insurance/info/upload/infile',
    method: 'post',
    data,
    headers: {
      contentType: 'multipart/form-data'
    }
  })

/**
 *  信息表文件上传（重疾）
 */
export const fetchUpdateInsuranceFile2 = (data: any) =>
  request({
    url: '/blackcat/insurance/info/illness/upload/infile',
    method: 'post',
    data,
    headers: {
      contentType: 'multipart/form-data'
    }
  })

/**
 *  信息表文件上传（寿险）
 */
export const fetchUpdateInsuranceFile3 = (data: any) =>
  request({
    url: '/blackcat/insurance/info/life/upload/infile',
    method: 'post',
    data,
    headers: {
      contentType: 'multipart/form-data'
    }
  })
