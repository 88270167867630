





































import { Vue, Component, Prop } from 'vue-property-decorator'
import { cloneDeep } from 'lodash'
import {
  fetchUpdateInsuranceFile1,
  fetchUpdateInsuranceFile2,
  fetchUpdateInsuranceFile3
} from '@/api/insuranceProduct'

@Component({
  name: 'dialog'
})

export default class extends Vue {
  @Prop({ required: false }) type!: string
  showDialog = false
  row = {
    name: ''
  }
  fileName = ''
  changeFile() {
    const oFile = this.$refs.file as HTMLInputElement
    if (oFile.files && oFile.files.length) {
      this.fileName = oFile.files[0].name
    }
  }
  conform() {
    const oFile = this.$refs.file as HTMLInputElement
    if (oFile.files && oFile.files.length) {
      const formData = new FormData()
      formData.append('file', oFile.files[0])
      formData.append('insuranceName', this.row.name)
      if (this.type === 'ChildrenEdu' || this.type === 'Pension') {
        fetchUpdateInsuranceFile1(formData).then((res) => {
          if (res.success) {
            this.$message.success('上传成功!')
            this.$emit('success')
            this.cancel()
          }
        })
      } else if (this.type === 'CriticalIllnessInsurance') {
        fetchUpdateInsuranceFile2(formData).then((res) => {
          if (res.success) {
            this.$message.success('上传成功!')
            this.$emit('success')
            this.cancel()
          }
        })
      } else if (this.type === 'LifeInsurance') {
        fetchUpdateInsuranceFile3(formData).then((res) => {
          if (res.success) {
            this.$message.success('上传成功!')
            this.$emit('success')
            this.cancel()
          }
        })
      }
    } else {
      this.$message.error('请选择文件')
    }
  }
  cancel() {
    this.showDialog = false
  }
  show(row: any) {
    this.row = cloneDeep(row)
    this.showDialog = true
  }
}

