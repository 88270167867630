





















































































































































import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import {
  formatInput,
  checkType,
  isDecimal,
  isInteger,
  isDecimalFour,
  isMoney,
  checkCard,
  checkCard2,
  isPhone,
  isPassword,
  checkCard3,
  checkCardTW,
  checkCardTB,
  checkCardAM,
  checkCardGAJ,
  isNine,
  isDouDecimal,
  isFtoN,
  isFourDecimal,
  isFtoHn,
  isToFn,
  isToTh,
  urlHeader,
  urlHeader2,
  urlWatch,
  email,
  interTwo,
  isEcpInteger,
  isTwDec,
  isTwHun,
  isPositiveInteger,
  isTwMoney,
  isFourMoney,
  isLandline,
  regUrl,
  isFourInteger
} from '@/utils/validate'
interface SelectPlaceholder {
    'select': string,
    'datetime': string,
    'date': string,
    'radio': string,
    [propName: string]: any
}

@Component({
  name: 'formItem'
})

export default class extends Vue {
 @Prop([Number, String, Object, Function, Array, Boolean]) value!: Number|String|Object|Function | Boolean
 @Prop(String)required!: String
 @Prop(String) type!: string
 @Prop(String) label!: string
 @Prop(Boolean) inline!: boolean
 @Prop(String) prepend!: String
 @Prop(String) append!: String
 @Prop(String) outter!: String
 @Prop(String) otherLabel!: String
 @Prop([String, Number]) maxs!: string | number
 @Prop(Boolean) disabled!: boolean
 @Prop(Boolean) readonly!: boolean
 @Prop({ default: '请输入' }) placeholder!: String
 @Prop(Boolean) double!: boolean
 @Prop(String) direct!: String // 有'left'和‘top’两个值，left决定标签在左侧否则在input上方
 @Prop(Boolean) clearable!: boolean
 @Prop(Boolean) search!: boolean // 下拉选择框 可搜索配置  true 可搜索  默认不可搜索
 @Prop(Boolean) multiple!: boolean
 @Prop(Function) remoteMethod!:Function
 @Prop(Boolean) remote!: boolean
 @Prop(Boolean) isSelectObj!: boolean
 @Prop(Boolean) showField!: boolean
 @Prop(Boolean) showInput!: boolean
 @Prop(Number) debounce!: Number
 @Prop(Function) formatTooltip!:Function
 @Prop(Function) clickLabel!:Function // 点击输入框外文本的方法定义
 @Prop(Boolean) showTooltip!: boolean
 @Prop(Boolean) controls!: boolean
 @Prop(String) linkLine!: String // 输入框后面是否有连线
 @Prop([String, Number, Array, Date, Object, Function, RegExp]) input!: String | number | object | Function | RegExp
 @Prop(String) msg!: String // 输入提示语 最高权限
 @Prop([String, Number]) documentType!: String | Number
 @Prop(String) prop!: String
 @Prop(String) suffix!: String
 @Prop(Boolean) per!: boolean
 @Prop({
   default: () => ({
     label: 'label',
     value: 'value',
     key: 'key'
   })
 }) defaultKey!:object
 @Prop(Array) options!: []
 @Prop(Array) checkItem!: []
 @Prop(Array) size!: []
  private radio = 0
  private cancelError:boolean = false
  private check:boolean = false
  private validatorStatus:boolean = false
  private mostError = ''
  private alertMessage: SelectPlaceholder = {
    'select': '请选择',
    'datetime': '请选择',
    'date': '请选择',
    'radio': '请选择'
  }
  private validatorType = this.required && checkType(this.required) === '[object string]' && this.required.split(':')[0]
  private patterns = ''
  get cloneValue() { // 把cloneValue值存进vuex，这里是取值
    return this.value
  }
  set cloneValue(value:any) {
    this.$emit('input', value)
  }
  // 绑定对象中的某个属性，只有当该属性发生变化时，触发该动作
  @Watch('value', { immediate: false, deep: false })
  onPersonChanged1(newVal: any) {
    this.cloneValue = newVal
  }
  // get 相当于computed
  private get showError() { // 显示错误提示
    return this.mostError || ((this.validatorStatus || this.check) && ((this.required !== undefined) || this.input) && this.validator({ type: this.required || this.input, value: this.cloneValue }))
  }
  private get defaultAlert() {
    return this.alertMessage[this.type] ? this.alertMessage[this.type] + this.labelText : `请输入${this.labelText}`
  }
  private labelText() {
    return this.label ? this.label.replace(/(：|:)/g, '') : '内容'
  }
  private oldValue = ''
  private mounted() {
  }
  private focus() {
    this.oldValue = this.cloneValue
  }
  public checkValue() {
    this.validatorStatus = true
    this.check = true
    return this.showError
  }
  public checkErrorValue() {
    return this.cloneValue && this.showError
  }
  private blur() {
    (!this.cloneValue && (this.required === undefined)) && (this.cancelError = true)
    this.$emit('onBlur')
    if (this.validatorType === 'isCard') {
      if (this.oldValue === this.cloneValue) {
        return
      }
      if (!this.cloneValue) {
        this.mostError = '请输入证件号！'
        return
      }
      if (this.documentType === '2' || this.documentType === '99' || this.documentType === '3') {
        if (this.cloneValue.length < 8 || this.cloneValue.length > 20) {
          this.mostError = '护照或其他证件类型长度最少8位最多20位'
          return
        }
        if (!checkCard2(this.cloneValue)) {
          this.mostError = '只能输入数字、字母、汉字类型'
          return
        }
      } else if (this.documentType === '1') {
        if (this.cloneValue.length !== 18) {
          this.mostError = '身份证类型为18位！'
          return
        }
        if (!checkCard(this.cloneValue)) {
          this.mostError = '只能输入数字、字母类型'
          return
        }
      } else if (this.documentType === '4') {
        if (!checkCard3(this.cloneValue) || this.cloneValue.length > 10) {
          this.mostError = ' 只能输入数字 字母 括号'
          return
        }
      } else if (this.documentType === '5') {
        if (!checkCardTW(this.cloneValue) || this.cloneValue.length > 10) {
          this.mostError = '只能输入数字 字母'
          return
        }
      }
      switch (this.documentType) {
        case '6': // 台胞证
          if (!checkCardTB(this.cloneValue)) {
            this.mostError = '证件号码格式不正确'
            return
          }
          break
        case '7': // 澳门身份证
          if (!checkCardAM(this.cloneValue)) {
            this.mostError = '证件号码格式不正确'
            return
          }
          break
        case '8': // 港澳居民来往内地通行证
          if (!checkCardGAJ(this.cloneValue)) {
            this.mostError = '证件号码格式不正确'
            return
          }
          break
      }
      if (!this.documentType) return
      this.$store.dispatch('CHECK_CARD', {
        param: {
          documentType: this.documentType,
          documentNo: this.cloneValue
        }
      }).then(res => {
        // 验证身份证号码校验之后 根据身份证是否合法 调用子组件方法
        this.$emit('onBlurCardCallback', res.success)
        if (!res.success) {
          this.mostError = res.msg
        } else {
          return ''
        }
      })
    }
  }
  private updateValue(val:any) {
    this.check = false
    this.cancelError && (this.cancelError = false)
    !this.validatorStatus && (this.validatorStatus = true)
    if (this.double) {
      this.$emit('change', val)
    } else {
      this.$emit('input', val)
    }
  }
  private validator({ type, value }: { type:any; value:any }) {
    value = value === '<p><br></p>' ? '' : value
    value = Object.prototype.toString.call(value) === '[object Undefined]' ? '' : value
    value = Object.prototype.toString.call(value) === '[object Array]' ? value.length > 0 ? value : '' : value
    if (checkType(type) === '[object RegExp]') {
      return type.test(value) ? '' : this.msg || `请输入正确的${this.label || '数据'}`
    }
    if (checkType(type) === '[object Function]') {
      return type(value) ? '' : this.msg || `请输入正确的${this.label || '数据'}`
    }
    if (type && checkType(type) !== '[object String]') return
    const typeArr = type ? type.split(':') : []
    const nullMessage = this.msg || this.placeholder || this.prop || ''
    if (this.input && !value) return false
    switch (typeArr[0] || '') {
      case '':
        return value ? '' : typeArr[1] ? typeArr[1] : this.defaultAlert
      case 'isPhone':
        return ((value.length >= 11) || this.check) && (isPhone(value) ? '' : !value ? '请输入手机号' : typeArr[1] || '手机号格式不正确')
      case 'isInteger':
        return isInteger(value) ? '' : typeArr[1] || '请输入整数'
      case 'isDecimal':
        return isDecimal(value) ? '' : typeArr[1] || '请输入两位或四位小数'
      case 'isDecimalFour':
        return isDecimalFour(value) ? '' : typeArr[1] || '请输入四位以内的小数'
      case 'isMoney':
        return isMoney(value) ? '' : typeArr[1] || '请输入正确的金额'
      case 'isPassword':
        return ((value.length >= 6) || this.check) && (isPassword(value) ? '' : !value ? typeArr[1] || `请输入${nullMessage}` : typeArr[1] || '密码格式不正确')
      case 'isCard':
        if (this.documentType === '2' || this.documentType === '99' || this.documentType === '3') {
          if (!checkCard2(this.cloneValue)) {
            return '只能输入数字、字母、汉字类型'
          }
        } else if (this.documentType === '1') {
          if (this.cloneValue.length !== 18) {
            this.mostError = '身份证类型为18位！'
            return
          }
          if (!checkCard(this.cloneValue)) {
            return '只能输入数字、字母类型'
          }
        } else if (this.documentType === '4') {
          if (!checkCard3(this.cloneValue) || this.cloneValue.length > 10) {
            return '只能输入数字 字母 括号'
          }
        } else if (this.documentType === '5') {
          if (!checkCardTW(this.cloneValue) || this.cloneValue.length > 10) {
            return '只能输入数字 字母'
          }
        }
        switch (this.documentType) {
          case '6': // 台胞证
            if (!checkCardTB(this.cloneValue)) {
              return '证件号码格式不正确'
            }
            break
          case '7': // 澳门身份证
            if (!checkCardAM(this.cloneValue)) {
              return '证件号码格式不正确'
            }
            break
          case '8': // 港澳居民来往内地通行证
            if (!checkCardGAJ(this.cloneValue)) {
              return '证件号码格式不正确'
            }
            break
        }
        this.validatorType = 'isCard'
        if (!value) return '请输入证件号'
        break
      case 'isNine':
        return isNine(value) ? '' : typeArr[1] || '请输入0-99的数字'
      case 'isDouDecimal':
        return isDouDecimal(value) ? '' : typeArr[1] || '请输入两位小数'
      case 'isFtoN':
        return isFtoN(value) ? '' : typeArr[1] || '请输入1-99的整数'
      case 'isFourDecimal':
        return isFourDecimal(value) ? '' : typeArr[1] || '最多输入四位小数'
      case 'isFtoHn':
        return isFtoHn(value) ? '' : typeArr[1] || '请输入1-999的整数'
      case 'isToFn':
        return isToFn(value) ? '' : typeArr[1] || '请输入1-9999的整数'
      case 'isToTh':
        return isToTh(value) ? '' : typeArr[1] || '请输入1-30的整数'
      case 'urlHeader':
        return urlHeader(value) ? '' : typeArr[1] || '跳转地址必须以http://或https://开头！'
      case 'urlHeader2':
        return urlHeader2(value) ? '' : typeArr[1] || '跳转地址必须以http://或https://开头！'
      case 'urlWatch':
        return urlWatch(value) ? '' : typeArr[1] || '观看地址必须是以http:// 或 https://开头的地址！'
      case 'email':
        return email(value) ? '' : typeArr[1] || '邮箱格式错误，请重新输入'
      case 'interTwo':
        return interTwo(value) ? '' : typeArr[1] || '请输入大于0的数，最多允许两位小数'
      case 'isEcpInteger':
        return isEcpInteger(value) ? '' : typeArr[1] || '请输入正整数'
      case 'isTwDec':
        return isTwDec(value) ? '' : typeArr[1] || '允许输入大于0且最多输入两位小数'
      case 'isTwHun':
        return isTwHun(value) ? '' : typeArr[1] || '请输入两位小数'
      case 'isPositiveInteger':
        return isPositiveInteger(value) ? '' : typeArr[1] || '输入数字，T后的第N个工作日起息'
      case 'isTwMoney':
        return isTwMoney(value) ? '' : typeArr[1] || '最多允许输入两位小数'
      case 'isFourMoney':
        return isFourMoney(value) ? '' : typeArr[1] || '最多允许输入四位小数'
      case 'isLandline':
        return isLandline(value) ? '' : typeArr[1] || '请输入正确手机号码或座机号码'
      case 'regUrl':
        return regUrl(value) ? '' : typeArr[1] || '请输入https格式的详情链接地址'
      case 'isFourInteger':
        return isFourInteger(value) ? '' : typeArr[1] || '允许输入大于0且最多输入4位小数'
    }
  }
}

